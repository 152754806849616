.rcw-conversation-container > .rcw-header {
  background-color: #f22f46;
  height: 60px;
}

.rcw-conversation-container > .rcw-header > span {
  visibility: hidden;
}

.rcw-conversation-container > .rcw-header > .rcw-close-button {
  background-color: #f22f46;
}

.rcw-client {
  background-color: rgb(223, 134, 134);
}

.rcw-response {
  background-color: rgb(116, 116, 116);
}

.rcw-launcher {
  background-color: #f22f46;
}

.rcw-header > .rcw-title {
  padding: 0;
}

.rcw-widget-container {
  z-index: 1300;
}

@media screen and (max-width: 800px) {
  .rcw-widget-container.rcw-opened {
    height: 100%;
  }
}
